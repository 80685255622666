import React from 'react'
import PropTypes from 'prop-types'
import { categoryColors } from '../../../shared/style/colors'
import { css as glamorCSS } from 'glamor'
import PieChart from '../../pieChartBox/pieChart'
import HoverableHeader from '../../hoverableHeader'
import CustomTooltip from '../../expensesSummary/customTooltip'
import sumBy from 'lodash/sumBy'
import pluralize from 'pluralize'
import { withTheme, css } from 'styled-components'

const HEADER_HEIGHT = '50px'
const BOX_PADDING = '20px'

const Header = css`
  padding: 7px ${BOX_PADDING} 0 ${BOX_PADDING};
  border-bottom: 1px solid ${({ theme }) => theme.palette.border.primary};
`

const generateCSS = ({ theme }) => ({
  main: glamorCSS({
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    border: `1px solid ${theme.palette.border.primary}`,
    borderRadius: '8px'
  }),
  chartsContainer: glamorCSS({
    height: `calc(100% - ${HEADER_HEIGHT})`
  })
})

const className = 'appsCategoryChart'
const NUM_CATEGORY_TO_SHOW = 6

class AppsCategory extends React.Component {
  constructor (props) {
    super(props)
    const { theme } = props
    this.state = {
      CSS: generateCSS({ theme })
    }
  }

  componentDidUpdate (prevProps) {
    if (prevProps.theme !== this.props.theme) {
      this.setState({
        CSS: generateCSS({ theme: this.props.theme })
      })
    }
  }

  getDataForChart = (appsCategoryList, numCategoryToShow) => {
    if (appsCategoryList.length <= numCategoryToShow) {
      return appsCategoryList.map(entry => ({ ...entry, color: categoryColors[entry.name] }))
    }

    const top = appsCategoryList.slice(0, (numCategoryToShow - 1))
    const rest = appsCategoryList.slice((numCategoryToShow - 1))
    const other = {
      name: 'Other',
      value: sumBy(rest, appCategory => appCategory.value)
    }
    return [...top, other].map(entry => ({ ...entry, color: categoryColors[entry.name] }))
  }

  render () {
    const { loading, hasData, appsCategoryList } = this.props
    const data = loading ? [] : this.getDataForChart(appsCategoryList, NUM_CATEGORY_TO_SHOW)
    return (
      <div className={className} {...this.state.CSS.main}>
        <div>
          <HoverableHeader overrideStyle={Header} hoverClass={className} header='applications category' outerHoverStyle={'#appsCategory:hover &'} subHeader='Number of applications per category' />
        </div>
        <div {...this.state.CSS.chartsContainer}>
          <PieChart data={data} loading={loading} hasData={hasData} customToolTip={<CustomTooltip valueDisplayFunction={(value) => pluralize('App', value, true)} />} />
        </div>
      </div>
    )
  }
}

AppsCategory.propTypes = {
  hasData: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  appsCategoryList: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.number.isRequired
  }))
}
export default withTheme(AppsCategory)
