import styled from 'styled-components'
import { fontSize } from '../../../shared/style/sizes'

export const StyledHeader = styled.div`
  color: ${({ theme }) => theme.palette.text.primary};
  font-size: 40px;
  line-height: 40px;
  font-weight: 600;
  margin-bottom: 10px;
`

export const StyledSubHeader = styled.div`
  font-size: ${fontSize.small};
  font-weight: 600;
  line-height: 18px;
  color: ${({ theme }) => theme.palette.text.secondary};
  text-transform: uppercase;
`
