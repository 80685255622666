import Analytics from '../../helpers/analytics'
import React, { createRef } from 'react'
import { Link as ReactRouterLink } from 'react-router-dom'
import config from '../../config'
import Recaptcha from 'react-google-recaptcha'
import { safeRedirect } from '@shared/utils'
import { Field, Form } from 'react-final-form'
import SubmitButton from '@components/submitButton'
import { Button, ButtonType, ButtonSize, Link, AlertBox, AlertBoxType, Stack, H3, Body2, Body1, Input, FormElement } from '@toriihq/design-system'
import { base64Encode, base64Decode } from '@helpers/base64'
import { FullWidth } from '@shared/style/fullWidth'

class Login extends React.Component {
  static REMEMBER_ME_LOCAL_STORAGE_KEY = 'remember_me'

  state = {
    prefersPassword: false,
    prefersLink: false,
    rememberMeEmailAddress: undefined
  }

  emailSetupInput = {
    email: createRef(),
    captcha: createRef()
  }
  passwordSetupInput = {
    password: createRef(),
    captcha: createRef()
  }

  setPrefersPassword = async () => {
    this.setState({ prefersPassword: true, prefersLink: false })
  }

  setPrefersLink = async () => {
    this.setState({ prefersPassword: false, prefersLink: true })
  }

  async componentDidMount () {
    try {
      const rememberMeEmailAddress = localStorage.getItem(Login.REMEMBER_ME_LOCAL_STORAGE_KEY)
      if (rememberMeEmailAddress) {
        this.setState({
          rememberMeEmailAddress: base64Decode(rememberMeEmailAddress)
        })
      }

      const { getIsLoggedIn, getMe } = this.props
      const { isLoggedIn } = await getIsLoggedIn()
      if (isLoggedIn) {
        const me = await getMe()
        Analytics.identify(me)
      }
    } catch (e) {
      return e
    }
  }

  componentDidUpdate (prevProps) {
    const { onLoginSuccess } = prevProps
    const { authState, logoutState } = this.props
    if (authState === 'AUTHENTICATED' && (logoutState.success || !logoutState.error)) {
      onLoginSuccess()
    }
  }

  onInstantLogin = () => {
    const { loginState, instantAccessLogin, next } = this.props

    return instantAccessLogin(loginState.SAMLCheck.email, next)
  }

  handleEmailSubmit = () => {
    const { isLoginUsingSAML, loginState: { SAMLCheck }, logoutState, resetLogout } = this.props
    logoutState.success && resetLogout()

    const email = this.emailSetupInput.email.current.value

    return isLoginUsingSAML({
      email,
      captcha: SAMLCheck.data.captchaNeeded ? this.emailSetupInput.captcha.current.getValue() : undefined
    })
      .then((response) => {
        localStorage.setItem(Login.REMEMBER_ME_LOCAL_STORAGE_KEY, base64Encode(email))

        if (response && response.loginUrl) {
          if (response.allowLoginsViaEmailAndPassword) {
            setTimeout(() => {
              const { prefersPassword } = this.state
              if (!prefersPassword) {
                safeRedirect(response.loginUrl)
              }
            }, 3 * 1000)
          } else {
            safeRedirect(response.loginUrl)
          }
        }
      })
      .catch((e) => {
        SAMLCheck.data.captchaNeeded && this.emailSetupInput.captcha.current && this.emailSetupInput.captcha.current.reset()
        throw e
      })
  }

  handlePasswordSubmit = () => {
    const { login, loginState, onLoginSuccess } = this.props
    const { data, SAMLCheck } = loginState

    return login({
      email: SAMLCheck.email,
      password: this.passwordSetupInput.password.current.value,
      captcha: data.captchaNeeded ? this.passwordSetupInput.captcha.current.getValue() : undefined
    }).then(onLoginSuccess)
      .catch((e) => {
        data.captchaNeeded && this.passwordSetupInput.captcha.current && this.passwordSetupInput.captcha.current.reset()
        throw e
      })
  }

  renderEmailField = props => {
    const { meta, input, ...rest } = props
    return (
      <FormElement label='Email' errorMessage={meta.touched ? meta.error : null}>
        <Input
          ref={this.emailSetupInput.email}
          autoFocus
          suffix='User'
          hasError={Boolean(meta.touched && meta.error)}
          {...input}
          {...rest}
        />
      </FormElement>
    )
  }

  renderEmailStep = (alerts, allowLoginsViaEmailAndPassword) => {
    const { isFromCatalog, loginState } = this.props
    const { loading, SAMLCheck } = loginState
    const { rememberMeEmailAddress } = this.state
    const initialValues = { email: rememberMeEmailAddress }

    return <Form
      key={'emailForm'}
      onSubmit={this.handleEmailSubmit}
      initialValues={initialValues}
      render={(formProps) => {
        const { handleSubmit, hasValidationErrors } = formProps
        return <form onSubmit={handleSubmit}>
          <fieldset>
            <Stack direction='column' gap='space-200' alignItems='center'>
              <Field
                disabled={loading}
                name='email'
                placeholder='Email'
                component={this.renderEmailField}
                validate={emailValidation}
              />
              {allowLoginsViaEmailAndPassword && <Body2>Redirecting...(<Button type={ButtonType.compact} size={ButtonSize.small} onClick={this.setPrefersPassword} label='or click here to login using a password' />)</Body2>}
              {SAMLCheck.data.captchaNeeded && <Recaptcha
                name='samlcheck-captcha'
                ref={this.emailSetupInput.captcha}
                sitekey={config.recaptcha.sitekey}
              />}
              {!allowLoginsViaEmailAndPassword && <SubmitButton form={{ handleSubmit, valid: !hasValidationErrors }} label='Next' />}
              {alerts}
              {!isFromCatalog && <ReactRouterLink to={config.catalogBaseUrl}><Link>Application catalog</Link></ReactRouterLink>}
            </Stack>
          </fieldset>
        </form>
      }}
    />
  }

  renderPasswordStep = (alerts, isNotUsingSAML) => {
    const { loginState, location } = this.props
    const { hasError, data, help, loading } = loginState
    const locationDescriptor = {
      search: location.search
    }
    const forgotPasswordLink = <ReactRouterLink to={{ ...locationDescriptor, pathname: '/login/forgot' }}><Link>Forgot your password?</Link></ReactRouterLink>

    return <Form
      key={'passswordForm'}
      onSubmit={this.handlePasswordSubmit}
      render={(formProps) => {
        const { handleSubmit, hasValidationErrors } = formProps
        return <form onSubmit={handleSubmit}>
          <fieldset>
            <Stack direction='column' gap='space-200'>
              <FormElement label='Password' errorMessage={hasError.password ? help.password : null}>
                <Input
                  ref={this.passwordSetupInput.password}
                  autoFocus
                  disabled={loading}
                  name='password'
                  placeholder='Password'
                  type='password'
                  suffix='Lock'
                  hasError={hasError.password}
                  help={help.password}
                />
              </FormElement>
              {alerts}
              {data.captchaNeeded ? <Recaptcha
                name='captcha'
                ref={this.passwordSetupInput.captcha}
                sitekey={config.recaptcha.sitekey}
              /> : null}
              <Stack direction='column' gap='space-200' alignItems='center'>
                <SubmitButton form={{ handleSubmit, valid: !hasValidationErrors }} label='Sign In' recoveryTime={0} />
                <div> {forgotPasswordLink} </div>
                {isNotUsingSAML && <Body2>You can also <Button type={ButtonType.compact} size={ButtonSize.small} onClick={this.setPrefersLink} label='sign in with a magic link' /></Body2>}
              </Stack>
            </Stack>
          </fieldset>
        </form>
      }}
    />
  }

  renderLinkStep = (alerts) => {
    return <>
      <Stack direction='column' gap='space-200' alignItems='center'>
        <Body1>Get a magic link sent to your email <br /> that'll sign you in instantly</Body1>
        {alerts}
        <Button onClick={this.onInstantLogin} label='Go' />
        <Body2>You can also <Button type={ButtonType.compact} size={ButtonSize.small} onClick={this.setPrefersPassword} label='login using password' /></Body2>
      </Stack>
    </>
  }

  render () {
    const alerts = []
    const { authState, loginState, isEmailSent, isFromCatalog, isExtensionLogin, logoutState } = this.props
    const { prefersLink, prefersPassword } = this.state
    const { success, error, redirect, SAMLCheck } = loginState
    const isAuthenticated = !redirect && (success || (authState === 'AUTHENTICATED' && (logoutState.success || !logoutState.error)))
    const isNotUsingSAML = loginState.SAMLCheck.isSAML === false
    const allowLoginsViaEmailAndPassword = loginState.SAMLCheck.allowLoginsViaEmailAndPassword

    if (isAuthenticated) {
      alerts.push(
        <AlertBox key='success' type={AlertBoxType.POSITIVE} description='Success. Redirecting...' />
      )
    }

    if (redirect) {
      alerts.push(
        <AlertBox key='success' type={AlertBoxType.POSITIVE} description='Redirecting...' />
      )
    }

    if (error) {
      alerts.push(
        <AlertBox key='error' type={AlertBoxType.NEGATIVE} description={error} />
      )
    }

    if (SAMLCheck.error) {
      alerts.push(
        <AlertBox key='SAMLCheck-error' type={AlertBoxType.NEGATIVE} description={SAMLCheck.error} />
      )
    }

    if (isEmailSent) {
      alerts.push(
        <AlertBox key='success' type={AlertBoxType.POSITIVE} description='Check your email to complete signing in' />
      )
    }

    let content = null
    if (isAuthenticated || redirect || isEmailSent) {
      content = <>{alerts}</>
    } else {
      if (!isNotUsingSAML && (!allowLoginsViaEmailAndPassword || !prefersPassword)) {
        content = this.renderEmailStep(alerts, allowLoginsViaEmailAndPassword)
      } else if ((isFromCatalog || isExtensionLogin || prefersLink) && !prefersPassword) {
        content = this.renderLinkStep(alerts)
      } else {
        content = this.renderPasswordStep(alerts, isNotUsingSAML)
      }
    }

    return (
      <Stack direction='column' gap='space-500' alignItems='center'>
        <H3>Sign in to Torii</H3>
        <FullWidth>{content}</FullWidth>
      </Stack>
    )
  }
}

const emailValidation = value => !value || !/^\S+@\S+\.\S+/.test(value) ? 'Invalid email address' : undefined

export default Login
