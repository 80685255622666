import { ReactElement, useEffect, useState, useCallback } from 'react'
import Page from '@components/page'
import { useSelector, useDispatch } from 'react-redux'
import { getDashboards as getDashboardsSelector } from '@selectors/dashboards'
import { getContractsFields, getTransactionsFieldValues, getUserDetailsFields } from '@store/actions'
import { isToriiAdmin } from '@selectors/me'
import { getCurrentOrg } from '@selectors/org'
import { getDashboards, saveLastViewedDashboard } from '@actions/dashboards'
import { PageContentContainer } from './styles'
import { Dashboard as DashboardType } from '@reducers/dashboards/types'
import { useHistory, useParams } from 'react-router-dom'
import Dashboard from './dashboard'
import Drawer from '@components/drawer'
import { getDashboardWidgetEditConfigDrawer, getUserPreferences } from '@selectors/ui'
import DashboardEditWidget from 'src/components/dashboard/dashboardEditWidget'
import { toggleDashboardWidgetEditConfigDrawer } from '@store/actions/dashboards'
import { Menu, Button, ButtonSize, ButtonType } from '@toriihq/design-system'
import { RouteParams } from './types'
import { noop } from 'lodash'
import { TRANSACTIONS_FIELDS_VALUES_TO_FETCH } from '@components/expensesTable/utils'
import { MAPPING_STATUSES } from '@components/dashboard/dataPopups/dynamicDataPopup/transactionDataPopup'
import initialDashboardsBySystemKey from '@actions/initialDashboards'
import Analytics from '@components/dashboard/analytics'

const DashboardsPage = (): ReactElement => {
  const dispatch = useDispatch()
  const history = useHistory()
  const params = useParams() as RouteParams

  const dashboards = useSelector(getDashboardsSelector)
  const isUserToriiAdmin = useSelector(isToriiAdmin)
  const { id: idOrg } = useSelector(getCurrentOrg)
  const { isOpen: isDrawerOpen } = useSelector(getDashboardWidgetEditConfigDrawer)
  const userPreferences = useSelector(getUserPreferences)
  const lastViewedDashboardId = userPreferences.lastViewedDashboard?.id

  const [selectedDashboard, setSelectedDashboard] = useState<DashboardType | null>(null)

  useEffect(() => {
    if (idOrg) {
      dispatch(getDashboards({ idOrg }))
      dispatch(getContractsFields({ idOrg }))
      dispatch(getUserDetailsFields({ idOrg }))
      dispatch(getTransactionsFieldValues({ idOrg, idApp: undefined, mappingStatus: MAPPING_STATUSES, fields: TRANSACTIONS_FIELDS_VALUES_TO_FETCH }))
    }
  }, [dispatch, idOrg])

  useEffect(() => {
    if (dashboards.length > 0) {
      const defaultDashboard = dashboards[0]
      const defaultURL = `/team/${idOrg}/dashboards/${defaultDashboard.id}`

      if (params.idDashboard) {
        const isProductTestDashboard = (params.idDashboard === initialDashboardsBySystemKey.productTest.id)
        const dashboard = isProductTestDashboard && isUserToriiAdmin
          ? initialDashboardsBySystemKey.productTest
          : dashboards.find(d => d.id === Number(params.idDashboard))
        if (dashboard) {
          setSelectedDashboard(dashboard)
        } else {
          history.replace(defaultURL)
        }
      } else if (lastViewedDashboardId) {
        const dashboard = dashboards.find(d => d.id === lastViewedDashboardId)
        if (dashboard) {
          history.replace(`/team/${idOrg}/dashboards/${lastViewedDashboardId}`)
        } else {
          history.replace(defaultURL)
        }
      } else {
        history.replace(defaultURL)
      }
    }
  }, [dashboards, params.idDashboard, history, idOrg, isUserToriiAdmin, lastViewedDashboardId])

  useEffect(() => {
    if (selectedDashboard?.id) {
      Analytics.viewDashboard({ dashboardName: selectedDashboard.title, dashboardSystemKey: selectedDashboard.systemKey })
      dispatch(saveLastViewedDashboard(selectedDashboard.id))
    }
  }, [selectedDashboard?.id, selectedDashboard?.title, selectedDashboard?.systemKey, dispatch])

  const handleDashboardSelect = (dashboard: DashboardType) => {
    Analytics.switchDashboard({ dashboardName: dashboard.title, dashboardSystemKey: dashboard.systemKey })
    history.push(`/team/${idOrg}/dashboards/${dashboard.id}`)
  }

  const onDrawerClose = useCallback(() => {
    dispatch(toggleDashboardWidgetEditConfigDrawer({ isOpen: false, widget: {}, idDashboard: null, sections: [], onWidgetUpdate: () => {} }))
  }, [dispatch])

  const menuElement = dashboards.length > 1 ? (
    <div data-intercom-target='dashboards-menu'>
      <Menu maxWidth={160}
        items={[ { label: 'Dashboards', type: 'header' },
          ...dashboards.map(dashboard => ({
            type: 'item',
            element: <Menu.Item isSelected={dashboard.id === selectedDashboard?.id} key={dashboard.id} onClick={() => handleDashboardSelect(dashboard)}>{dashboard.title}</Menu.Item>
          }))
        ]}>
        <Button type={ButtonType.secondary} size={ButtonSize.small} icon='ChevronDown' onClick={noop} />
      </Menu>
    </div>
  ) : null

  return (
    <Page title='Dashboards'>
      <PageContentContainer>
        <Drawer
          isOpen={isDrawerOpen}
          title='Edit Widget'
          drawerContent={<DashboardEditWidget dashboardName={selectedDashboard?.title} dashboardSystemKey={selectedDashboard?.systemKey} />}
          onClose={onDrawerClose}>
          {selectedDashboard && (
            <Dashboard
              dashboard={selectedDashboard}
              menuElement={menuElement}
              isDrawerOpen={isDrawerOpen}
            />
          )}
        </Drawer>
      </PageContentContainer>
    </Page>
  )
}

export default DashboardsPage
