import React from 'react'
import { css as glamorCSS } from 'glamor'
import HoverableHeader from '../../hoverableHeader'
import MapChart from './mapChart'
import PropTypes from 'prop-types'
import Analytics from '../../../helpers/analytics'
import UsersMapPopup from '../../popups/usersMapPopup'
import VisibleFor from '@components/visibleFor'
import { SCOPES } from '@root/constants'
import EnableFor from '@components/enableFor'
import { Button, ButtonType, ButtonSize } from '@toriihq/design-system'
import { withTheme, css } from 'styled-components'

const HEADER_HEIGHT = '50px'
const BOX_PADDING = '20px'
const className = 'usersMap'

const Header = css`
  padding: 7px ${BOX_PADDING} 0 ${BOX_PADDING};
  border-bottom: 1px solid ${({ theme }) => theme.palette.border.primary};
`

const generateCSS = ({ theme }) => ({
  main: glamorCSS({
    border: `1px solid ${theme.palette.border.primary}`,
    borderRadius: '8px',
    height: '100%'
  }),
  container: glamorCSS({
    height: `calc(100% - ${HEADER_HEIGHT})`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  })
})

const fetchData = (props) => {
  const { idOrg, getUsersDistribution } = props
  idOrg && getUsersDistribution({ idOrg })
}

class UsersMap extends React.Component {
  constructor (props) {
    super(props)
    const { theme } = props
    this.state = {
      isOpen: false,
      CSS: generateCSS({ theme })
    }
  }

  componentDidMount () {
    fetchData(this.props)
  }

  componentDidUpdate (prevProps) {
    if (prevProps.idOrg !== this.props.idOrg) {
      fetchData(this.props)
    }

    if (prevProps.theme !== this.props.theme) {
      this.setState({
        CSS: generateCSS({ theme: this.props.theme })
      })
    }
  }

  togglePopup = () => {
    const nextPopUpState = !this.state.isOpen
    this.setState({ isOpen: nextPopUpState, country: undefined })
    Analytics.track(`Clicked ${nextPopUpState ? 'show' : 'hide'} users login`, {
    })
  }

  onCountryClick = (country) => {
    this.setState({ isOpen: true, country })
  }

  render () {
    const { data, loading, onCountryClick } = this.props
    const { isOpen, country, CSS } = this.state
    const onCountryClickMethod = onCountryClick || ((country) => this.onCountryClick(country))

    const seeAllButton = (
      <VisibleFor scopes={[SCOPES.USERS_READ]}>
        <Button type={ButtonType.compact} size={ButtonSize.small} onClick={this.togglePopup} label='See all' />
      </VisibleFor>
    )

    return (
      <div className={className} {...CSS.main}>
        <HoverableHeader hoverClass={className} overrideStyle={Header} outerHoverStyle={'#usersMap:hover &'} header='Users Map' subHeader='Last 30 days usage locations' rightContent={seeAllButton} />
        <div {...CSS.container}>
          <EnableFor scopes={[SCOPES.USERS_READ]} allowForToriiAdmin showAsDisabled={false}>
            <MapChart data={data} loading={loading} onCountryClick={onCountryClickMethod} />
          </EnableFor>
        </div>
        {isOpen && <UsersMapPopup country={country} isOpen={isOpen} cancel={this.togglePopup} />}
      </div>
    )
  }
}

UsersMap.propTypes = {
  data: PropTypes.array,
  loading: PropTypes.bool
}

export default withTheme(UsersMap)
