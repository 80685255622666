import styled, { css } from 'styled-components'
import colors from '@shared/style/colors'
import texts from '@shared/style/texts'
import { css as glamorCSS } from 'glamor'

const HEADER_HEIGHT = '50px'
const BOX_PADDING = '20px'
const ROW_HEIGHT = '30px'

export const Main = styled.div`
  height: 100%;
  border: 1px solid ${({ theme }) => theme.palette.border.primary};
  border-radius: 8px;
`

export const Header = css`
  min-height: ${HEADER_HEIGHT};
  padding: 7px ${BOX_PADDING} 0 ${BOX_PADDING};
  border-bottom: 1px solid ${({ theme }) => theme.palette.border.primary}
`

export const Container = glamorCSS({
  display: 'flex',
  width: '100%',
  height: `calc(100% - ${HEADER_HEIGHT} - 3px)`
})

export const Separator = styled.div`
  border-right: 1px solid ${({ theme }) => theme.palette.border.primary};
`

export const Indexing = glamorCSS({
  alignSelf: 'center',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  color: colors.grey2,
  width: '40px'
})

export const Chart = glamorCSS({
  paddingLeft: BOX_PADDING,
  alignSelf: 'center',
  width: '100%'
})

export const Index = glamorCSS(texts.body, {
  height: ROW_HEIGHT,
  lineHeight: ROW_HEIGHT
})

export const ActiveUsers = styled.div`
  font: ${({ theme }) => theme.typography.font.body02};
  color: ${colors.black};
  width: 50px;
`

export const AppRow = styled.div`
  height: ${ROW_HEIGHT};
  display: flex;
  align-items: center;
`
