import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  border: 1px solid ${({ theme }) => theme.palette.border.primary};
  border-radius: 8px;
  padding: 20px 37px 14px 31px;
  
  @media(max-width: 600px) {
    padding: 26px 37px;
  }
`

export const Description = styled.div`
  height: 24px;
  display: flex;
  margin-top: 12px;
  color: ${({ theme }) => theme.palette.text.secondary};
  
  div {
    align-self: flex-end;
  }
`

export const Main = styled.div`
  position: relative;
  width: 100%;
`

export const NavToLink = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  opacity: 0;
  transition: opacity .2s;
  
  ${Container}:hover & {
    opacity: 1;
  }
`

export const EmptyStateContainer = styled.div`
  border: 1px solid ${({ theme }) => theme.palette.border.primary};
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  border-radius: 8px;
  padding: 20px 37px 14px 31px;
  flex-wrap: wrap;
`
