import React from 'react'
import { StyledHeader, StyledSubHeader } from './style'

export interface BoxHeaderProps {
  header?: React.ReactNode;
  subHeader?: React.ReactNode;
}

const BoxHeader = ({
  header,
  subHeader
}: BoxHeaderProps): JSX.Element => (
  <div>
    <StyledHeader>
      {header}
    </StyledHeader>
    <StyledSubHeader>
      {subHeader}
    </StyledSubHeader>
  </div>
)

export default BoxHeader
