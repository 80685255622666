import { Fragment, ReactElement, useEffect, useMemo, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Button, ButtonType, Icon, Menu, Spacer, Stack, Tooltip } from '@toriihq/design-system'
import { Responsive, WidthProvider } from 'react-grid-layout'
import { WidgetContainer, WidgetResizer, DashboardGridContainer, MenuItemWithTooltipContainer } from './styles'
import 'react-grid-layout/css/styles.css'
import 'react-resizable/css/styles.css'
import { Dashboard as DashboardType, DynamicWidget, FIXED_WIDGET_TYPES, FixedWidget, Widget, WIDGET_TYPES } from '@reducers/dashboards/types'
import { updateDashboard } from '@actions/dashboards'
import { EMPTY_ARRAY, FEATURE_FLAGS, UPDATE_USER_PREFERENCES } from '@root/constants'
import { getIdOrg, getSupportedFeatures as getSupportedFeaturesSelector } from '@root/store/selectors/org'
import { getDashboardWidgetEditConfigDrawer } from '@selectors/ui'
import { IdOrg } from '@root/store/types'
import initialDashboardsBySystemKey from '@root/store/actions/initialDashboards'
import { WIDGET_TO_CONFIG } from '@components/dashboard/widgets/constants'
import { toggleDashboardWidgetEditConfigDrawer } from '@store/actions/dashboards'
import DashboardWidgetDataPopup from './dataPopups'
import { getAllPlans } from '@store/actions'
import Analytics from './analytics'
import useDeepCompareEffect from 'use-deep-compare-effect'
import noop from 'lodash/noop'
import { useIsScrolling } from '@shared/hooks'
import { getTableWidgetTableKey } from '@components/dashboard/widgets/shared'
import { createSortObjectArrayFromSortStringArray } from '@shared/utils'
import FixedWidgetRenderer from './widgets/fixedWidgets'
import DynamicWidgetRenderer from './widgets/dynamicWidgets'
import { createExtraFilters } from '@components/dashboard/widgets/utils'
import { Filter } from '@shared/filters/types'
import { WidgetFormattedData } from './widgets/types'

const ResponsiveGridLayout = WidthProvider(Responsive)

const Resizer = (resizeHandleAxis: string, ref) => {
  return (
    <WidgetResizer className={`react-resizable-handle react-resizable-handle-${resizeHandleAxis}`} ref={ref} $resizeHandleAxis={resizeHandleAxis}>
      <Icon name='ChevronDownRight' />
    </WidgetResizer>
  )
}

type DashboardProps = {
  dashboard: DashboardType
  isEditMode: boolean
  onDashboardUpdate: (dashboard) => void
}

const DashboardWidgets = (props: DashboardProps): ReactElement => {
  const { dashboard, isEditMode, onDashboardUpdate } = props

  const dispatch = useDispatch()

  const idOrg: IdOrg = useSelector(getIdOrg)
  const { isOpen: isDrawerOpen } = useSelector(getDashboardWidgetEditConfigDrawer)
  const showResetDashboardButton = useSelector(getSupportedFeaturesSelector)[FEATURE_FLAGS.SHOW_RESET_DASHBOARD_BUTTON]

  const { id: idDashboard, title: dashboardName, systemKey: dashboardSystemKey } = dashboard
  const widgets = dashboard.widgets || EMPTY_ARRAY
  const layoutConfig = dashboard.layoutConfig || EMPTY_ARRAY

  const isScrolling = useIsScrolling()

  const [editedWidgets, setEditedWidgets] = useState(widgets)
  const [editedLayoutConfig, setEditedLayoutConfig] = useState(layoutConfig)
  const [allowAnimation, setAllowAnimation] = useState(false)
  const [idWidgetForViewDataPopup, setIdWidgetForViewDataPopup] = useState<number | null>(null)
  const [selectedIdWidget, setSelectedIdWidget] = useState<number | null>(null)
  const [selectedMenuIdWidget, setSelectedMenuIdWidget] = useState<number | null>(null)
  const [isDataPopupOpen, setIsDataPopupOpen] = useState(false)
  const [dataPopupOptions, setDataPopupOptions] = useState<{
    extraFilters: Filter[] | null;
    extraFiltersTitle: string | null;
    additionalDataPopupOptions: Record<string, any>
  }>({
    extraFilters: null,
    extraFiltersTitle: null,
    additionalDataPopupOptions: {}
  })

  useEffect(() => {
    const preventInitialAnimation = setTimeout(() => setAllowAnimation(true), 0)
    return () => clearTimeout(preventInitialAnimation)
  }, [])

  useEffect(() => {
    if (idOrg) {
      dispatch(getAllPlans())
    }
  }, [idOrg, dispatch])

  useEffect(() => {
    setEditedWidgets(widgets)
  }, [widgets])

  useDeepCompareEffect(() => {
    setEditedLayoutConfig(layoutConfig)
  }, [layoutConfig])

  useEffect(() => {
    if (!isDrawerOpen) {
      setSelectedIdWidget(null)
      setSelectedMenuIdWidget(null)
    }
  }, [isDrawerOpen])

  useEffect(() => {
    if (!isDataPopupOpen) {
      setSelectedMenuIdWidget(null)
    }
  }, [isDataPopupOpen])

  useEffect(() => {
    if (!isScrolling) {
      setSelectedMenuIdWidget(null)
    }
  }, [isScrolling])

  const layout = editedLayoutConfig.map((config) => {
    return {
      ...config,
      i: config.i.toString(),
      minW: 3,
      minH: 2
    }
  })

  const sortedLayout = layout.sort((a, b) => a.y - b.y || a.x - b.x)
  const layoutMobile = sortedLayout.map((widget, i) => {
    const previousWidget = sortedLayout[i - 1]
    const y = previousWidget ? previousWidget.y + previousWidget.h : 0
    return { ...widget, x: 0, w: 12, y, minW: 12, maxW: 12 }
  })

  const onWidgetUpdate = ({ updatedWidget }: { updatedWidget: Widget }) => {
    const newWidgets = editedWidgets.map((widget) => widget.id === updatedWidget.id ? updatedWidget : widget)
    setEditedWidgets(newWidgets)
    dispatch(toggleDashboardWidgetEditConfigDrawer({ idDashboard, widget: updatedWidget }))

    if (updatedWidget.type === WIDGET_TYPES.TABLE_WIDGET) {
      const table = getTableWidgetTableKey(updatedWidget)
      const columnsConfiguration = updatedWidget.displayConfig.fields
      const filters = updatedWidget.dataConfig.filters
      const defaultSort = createSortObjectArrayFromSortStringArray(updatedWidget.displayConfig.sort)

      dispatch({
        type: UPDATE_USER_PREFERENCES,
        payload: { table, userPreference: { columnsConfiguration, filters, defaultSort, sortedOnce: true } }
      })
    }
  }

  useEffect(() => {
    onDashboardUpdate({ ...dashboard, widgets: editedWidgets, layoutConfig: editedLayoutConfig })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editedWidgets, editedLayoutConfig])

  const onFilteredDrilldown = (widget: DynamicWidget, allData: WidgetFormattedData[], selectedData?: WidgetFormattedData): void => {
    let newExtraFilters: Filter[] | null = null
    let newExtraFiltersTitle: string | null = null
    const extraFilters = createExtraFilters(widget, allData, selectedData)
    if (selectedData && extraFilters.length > 0) {
      newExtraFilters = extraFilters
      newExtraFiltersTitle = selectedData.name || null
    }

    setDataPopupOptions({
      extraFilters: newExtraFilters,
      extraFiltersTitle: newExtraFiltersTitle,
      additionalDataPopupOptions: {}
    })

    setIdWidgetForViewDataPopup(widget.id)
    setIsDataPopupOpen(true)

    Analytics.clickOnDrillDown({ dashboardName, dashboardSystemKey, widgetTitle: widget?.displayConfig.label })
  }

  const onFixedWidgetFilteredDrillDown = ({ widget, ...rest }: { widget: FixedWidget, [key: string]: any }) => {
    switch (widget.fixedWidgetType) {
      case FIXED_WIDGET_TYPES.USERS_MAP:
        setDataPopupOptions({
          extraFilters: null,
          extraFiltersTitle: rest.countryName,
          additionalDataPopupOptions: {
            countryName: rest.countryName,
            countryCode: rest.countryCode
          }
        })
        break
    }

    setIdWidgetForViewDataPopup(widget.id)
    setIsDataPopupOpen(true)

    Analytics.clickOnDrillDown({ dashboardName, dashboardSystemKey, widgetTitle: widget?.displayConfig.label })
  }

  const onViewDataButtonClick = (widget: Widget) => {
    Analytics.clickOnDrillDown({ dashboardName, dashboardSystemKey, widgetTitle: widget?.displayConfig.label })

    if (isDrawerOpen) {
      setSelectedIdWidget(widget.id)
      const config = WIDGET_TO_CONFIG[widget.type]

      if (config) {
        dispatch(toggleDashboardWidgetEditConfigDrawer({ sections: config.editableSections, idDashboard, widget, onWidgetUpdate }))
      } else {
        dispatch(toggleDashboardWidgetEditConfigDrawer({ isOpen: false }))
      }
    }

    setDataPopupOptions({
      extraFilters: null,
      extraFiltersTitle: null,
      additionalDataPopupOptions: {}
    })
    setIdWidgetForViewDataPopup(widget.id)
    setIsDataPopupOpen(true)
  }

  const editModeMenuButtons = ({ widget }: { widget: Widget }) => {
    const shouldDisableEditButton = widget.type === WIDGET_TYPES.FIXED_WIDGET

    const editButton = shouldDisableEditButton
      ? <Tooltip label='This widget cannot be edited'>
        <MenuItemWithTooltipContainer>
          <Menu.Item key='edit' disabled>
            <Stack direction='row' gap='space-050'>
              <Icon name='Edit' color='disabled' />
              <div>Edit</div>
            </Stack>
          </Menu.Item>
        </MenuItemWithTooltipContainer>
      </Tooltip>
      : <Menu.Item key='edit' onClick={() => onEditButtonClick({ widget })}>
        <Stack direction='row' gap='space-050'>
          <Icon name='Edit' />
          <div>Edit</div>
        </Stack>
      </Menu.Item>

    const menuItems = [
      {
        type: 'item',
        element: editButton
      },
      {
        type: 'item',
        element: (
          <Menu.Item key='viewTable' onClick={() => onViewDataButtonClick(widget)}>
            <Stack direction='row' gap='space-050'>
              <Icon name='ViewTable' />
              <div>View Table</div>
            </Stack>
          </Menu.Item>
        )
      }
    ]

    return <Menu
      isOpen={isScrolling ? false : undefined}
      items={menuItems}
      onToggle={({ isOpen }) => {
        if (!isOpen && (selectedMenuIdWidget === widget.id)) {
          setSelectedMenuIdWidget(null)
        }
        return isOpen ? setSelectedMenuIdWidget(widget.id) : null
      }}
    >
      <Tooltip label='Actions'>
        <Button type={ButtonType.tertiary} icon='DotsVertical' onClick={noop} />
      </Tooltip>
    </Menu>
  }

  const viewModeButtons = ({ widget }: { widget: Widget }) => {
    return <Tooltip label='View data'>
      <Button icon='ViewTable' type={ButtonType.tertiary} onClick={() => onViewDataButtonClick(widget)} />
    </Tooltip>
  }

  const renderWidget = ({ widget }: { widget: Widget}): ReactElement | null => {
    const primaryButton = isEditMode
      ? editModeMenuButtons({ widget })
      : viewModeButtons({ widget })

    const properties = {
      idDashboard,
      widget,
      isSelected: (selectedIdWidget === widget.id),
      primaryButton,
      isWidgetMenuOpen: (selectedMenuIdWidget === widget.id),
      isEditMode,
      onWidgetUpdate
    }

    if (widget.type === WIDGET_TYPES.FIXED_WIDGET) {
      return <FixedWidgetRenderer {...properties} widget={widget as FixedWidget} onFixedWidgetFilteredDrillDown={onFixedWidgetFilteredDrillDown} />
    } else {
      return <DynamicWidgetRenderer {...properties} widget={widget as DynamicWidget} onFilteredDrilldown={onFilteredDrilldown} />
    }
  }

  const onWidgetClick = ({ widget }) => {
    if (!isEditMode) {
      return
    }

    setSelectedMenuIdWidget(null)
    onEditButtonClick({ widget })
  }

  const onEditButtonClick = ({ widget }) => {
    setSelectedIdWidget(widget.id)

    const config = WIDGET_TO_CONFIG[widget.type]

    if (config) {
      dispatch(toggleDashboardWidgetEditConfigDrawer({ isOpen: true, sections: config.editableSections, idDashboard, widget, onWidgetUpdate }))
    } else {
      dispatch(toggleDashboardWidgetEditConfigDrawer({ isOpen: false }))
    }
  }

  const onLayoutChange = (layout) => {
    if (!isEditMode) {
      return
    }

    const layoutConfig = layout.map(({ i, x, y, w, h }) => ({
      i: Number(i), x, y, w, h
    }))
    setEditedLayoutConfig(layoutConfig)
  }

  const onResizeStart = () => {
    setAllowAnimation(true)
  }

  const onResizeStop = (layout, oldItem, newItem) => {
    const widget = widgets.find(widget => Number(widget.id) === Number(oldItem.i))
    onWidgetClick({ widget })
    setAllowAnimation(false)

    if (oldItem.w !== newItem.w || oldItem.h !== newItem.h) {
      Analytics.resizeWidget({ dashboardName, dashboardSystemKey, widgetTitle: widget?.displayConfig.label })
    }
  }

  const onDragStart = () => {
    setAllowAnimation(true)
  }

  const onDragStop = (layout, oldItem, newItem) => {
    const widget = widgets.find(widget => Number(widget.id) === Number(oldItem.i))
    onWidgetClick({ widget })
    setAllowAnimation(false)

    if (oldItem.x !== newItem.x || oldItem.y !== newItem.y) {
      Analytics.dragWidget({ dashboardName, dashboardSystemKey, widgetTitle: widget?.displayConfig.label })
    }
  }

  const resetDashboard = () => {
    const initialDashboard = initialDashboardsBySystemKey[dashboard.systemKey]
    return dispatch(updateDashboard({ idOrg, idDashboard, dashboard: initialDashboard }))
  }

  const className = ['layout', (allowAnimation && isEditMode ? 'animated' : null)].filter(Boolean).join(' ')

  const widgetForViewDataPopup: Widget | undefined = useMemo(() => {
    if (!idWidgetForViewDataPopup) {
      return undefined
    }
    return editedWidgets.find(widget => widget.id === idWidgetForViewDataPopup)
  }, [editedWidgets, idWidgetForViewDataPopup])

  return (
    <Fragment>
      { isDataPopupOpen && widgetForViewDataPopup ? (
        <DashboardWidgetDataPopup
          isOpen={isDataPopupOpen}
          isEditMode={isEditMode}
          widget={widgetForViewDataPopup}
          onWidgetUpdate={onWidgetUpdate}
          idDashboard={idDashboard}
          onCloseAction={() => setIsDataPopupOpen(false)}
          dashboardName={dashboardName}
          dashboardSystemKey={dashboardSystemKey}
          extraFilters={dataPopupOptions.extraFilters}
          extraFiltersTitle={dataPopupOptions.extraFiltersTitle}
          additionalDataPopupOptions={dataPopupOptions.additionalDataPopupOptions}
        />
      ) : null }
      <DashboardGridContainer>
        <ResponsiveGridLayout
          isDraggable={isEditMode}
          isResizable={isEditMode}
          className={className}
          resizeHandle={Resizer}
          resizeHandles={['se', 'sw', 'nw', 'ne']}
          layouts={{ lg: layout, md: isEditMode ? layout : layoutMobile }}
          breakpoints={{ lg: 1000, md: 800 }}
          cols={{ lg: 12, md: 12, sm: 12, xs: 12, xxs: 12 }}
          rowHeight={40}
          containerPadding={[0, 0]}
          margin={[24, 24]}
          draggableCancel={'[role=button], .rt-resizable-header'}
          onLayoutChange={onLayoutChange}
          onDragStart={onDragStart}
          onDragStop={onDragStop}
          onResizeStart={onResizeStart}
          onResizeStop={onResizeStop}
        >
          {editedWidgets.map((widget) =>
            <WidgetContainer key={widget.id} id={widget.id.toString()} isEditMode={isEditMode}>
              { renderWidget({ widget }) }
            </WidgetContainer>
          )}
        </ResponsiveGridLayout>
        <Spacer top='space-200' >
          {showResetDashboardButton && <Button icon={'MagicWand'} onClick={() => resetDashboard()} label='Reset dashboard' />}
        </Spacer>
      </DashboardGridContainer>
    </Fragment>
  )
}

export default DashboardWidgets
