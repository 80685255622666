import React from 'react'
import PropTypes from 'prop-types'
import InfoBox from '../infoBox'
import { Button, ButtonType, Tooltip, Body1 } from '@toriihq/design-system'
import emptyImage from '@media/expenses.svg'
import { SCOPES } from '../../constants'
import Currency from '../currency'
import Analytics from '@helpers/analytics'
import EnableFor from '@components/enableFor'

const MILLION = (1000000 * 100)

const ExpenseBox = ({ loading, expenses, yearlyCost, toggleUploadExpenses }) => {
  const onClick = () => {
    Analytics.track('Click to upload expenses report', {
      from: 'Empty state expenses'
    })
    toggleUploadExpenses(true)
  }

  const emptyStateInfo = {
    orientation: 'horizontal',
    size: 'small',
    align: 'center',
    title: 'Integrate your expenses',
    image: <img src={emptyImage} alt='No expenses' />,
    buttons: [<EnableFor scopes={[SCOPES.EXPENSE_WRITE]} allowForToriiAdmin><Button type={ButtonType.secondary} onClick={onClick} label='Upload expenses report' /></EnableFor>]
  }

  const isOverMillion = (expenses >= MILLION)
  const fullDisplay = <Currency value={expenses} format={'$0,0'} />
  const compactDisplay = <Currency value={expenses} format={'$0,0.00a'} toUpperCase />

  const header = (
    <span>
      <Tooltip
        placement='top'
        label={<Currency value={expenses} />}
        hide={!isOverMillion}
      >
        <span>{isOverMillion ? compactDisplay : fullDisplay}</span>
      </Tooltip>
    </span>
  )

  return (
    <InfoBox
      header={expenses && header}
      subHeader='EXPENSES (LAST 12 MONTHS)'
      description={(yearlyCost > 0) && <div><Body1 as='span'><b><Currency value={yearlyCost} /></b> Active contracts value</Body1></div>}
      emptyStateInfo={emptyStateInfo}
      loading={loading}
      enableForFinanceUser
      navTo='/expenses'
      navToNeededScopes={[SCOPES.EXPENSE_READ]}
      pageName='Insights'
      boxName='Expenses'
    />
  )
}

ExpenseBox.propTypes = {
  expenses: PropTypes.number,
  yearlyCost: PropTypes.number,
  loading: PropTypes.bool
}

export default ExpenseBox
