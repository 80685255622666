import React from 'react'
import PropTypes from 'prop-types'
import ToriiPopup from '../ToriiPopupV2'
import UsersList from './usersList'

const UsersMapPopup = ({ cancel, isOpen, country }) => {
  return (
    <ToriiPopup isOpen={isOpen} onCloseAction={cancel} width='80%'>
      <ToriiPopup.Header header={`Users from ${(country?.countryName || 'all over the world')}`} />
      <ToriiPopup.Content contentArea>
        <UsersList country={country} />
      </ToriiPopup.Content>
    </ToriiPopup>
  )
}

UsersMapPopup.propTypes = {
  cancel: PropTypes.func,
  isOpen: PropTypes.bool,
  country: PropTypes.object
}

export default UsersMapPopup
