import React, { ReactElement, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Table from '@components/table'
import { TABLES } from '@root/constants'
import { canceledTasksColumns } from './columns'
import { IdOrg } from '@store/types'
import { getIdOrg } from '@store/selectors/org'
import { getUserPreferences } from '@store/selectors/ui'
import { sortStringFromArray } from '@shared/utils'
import { CanceledTasksState } from '@store/reducers/tasks/types'
import { getCanceledTasks as getCanceledTasksAction } from '@store/actions/tasks'
import { getCanceledTasks as getCanceledTasksSelector } from '@selectors/tasks'
import { GetTasksProps } from '../../types'
import useEffectOnce from '@shared/hooks/useEffectOnce'
import { useTaskSearch } from '../../useTaskSearch'

const ITEMS_PER_PAGE = 5 // TODO-OM implement

const CanceledTasksTab = (): ReactElement => {
  const idOrg: IdOrg = useSelector(getIdOrg)
  const dispatch = useDispatch()

  const userPreferences = useSelector(getUserPreferences)
  const { defaultSort = [] } = userPreferences[TABLES.canceledTasksTable.key] || {}
  const defaultSortString = sortStringFromArray(defaultSort)

  const { tasks, loading, loadingMore, total }: CanceledTasksState = useSelector(getCanceledTasksSelector)

  const getTasks = useCallback(async ({
    limit = ITEMS_PER_PAGE,
    offset = 0,
    q,
    reset = false
  }: GetTasksProps) => {
    await dispatch(getCanceledTasksAction({ idOrg, limit, offset, sort: defaultSortString, q, reset, filters: [] }))
  }, [defaultSortString, dispatch, idOrg])

  const { onSearch, searchText } = useTaskSearch({ getTasks })

  useEffectOnce(() => {
    getTasks({ reset: true, q: searchText })
  })

  const fetchCanceledTasksData = (reset = false): void => {
    getTasks({ offset: reset ? 0 : tasks.length, reset, q: searchText })
  }

  return <Table
    tableKey={TABLES.canceledTasksTable.key}
    data={tasks}
    columns={canceledTasksColumns}
    loading={loading}
    loadingMore={loadingMore}
    totalCount={total}
    fetchData={fetchCanceledTasksData}
    searchable
    forceShowSearch
    onSearch={onSearch}
    emptyStateMessage='No tasks to display' // TODO-OM define microcopy
  />
}

export default CanceledTasksTab
