import React from 'react'
import BoxHeader from './BoxHeader'
import Placeholder from '../placeholder'
import RelativeTeamLink from '../relativeTeamLink'
import Analytics from '@helpers/analytics'
import VisibleFor from '@components/visibleFor'
import { EmptyState, Link } from '@toriihq/design-system'
import {
  Container,
  Description,
  Main,
  NavToLink,
  EmptyStateContainer
} from './style'

interface InfoBoxProps {
  overrideStyle?: React.CSSProperties;
  loading?: boolean;
  center?: boolean;
  header?: React.ReactNode;
  subHeader: React.ReactNode;
  description: React.ReactNode;
  emptyStateInfo?: React.ComponentProps<typeof EmptyState>;
  navTo?: string;
  navToNeededScopes?: string[];
  pageName?: string;
  boxName?: string;
}

const InfoBox = ({
  overrideStyle,
  loading,
  center,
  header,
  subHeader,
  description,
  emptyStateInfo,
  navTo,
  navToNeededScopes,
  pageName,
  boxName
}: InfoBoxProps): JSX.Element | null => {
  const onClick = (pageName?: string, boxName?: string): void => {
    pageName && Analytics.track(`Click on See All / ${pageName}`, boxName && {
      'Block': boxName
    })
  }

  const renderInfoBox = (
    <Container style={overrideStyle}>
      <Placeholder loading={loading} type='rect' style={{ height: '55px', margin: center ? 'auto' : '' }}>
        <Main>
          <BoxHeader header={header} subHeader={subHeader} />
          <Description>{description}</Description>
          {navTo && (
            <VisibleFor scopes={navToNeededScopes}>
              <NavToLink>
                <RelativeTeamLink to={navTo} onClick={() => onClick(pageName, boxName)}>
                  <Link>See all</Link>
                </RelativeTeamLink>
              </NavToLink>
            </VisibleFor>
          )}
        </Main>
      </Placeholder>
    </Container>
  )

  if (loading || header) {
    return renderInfoBox
  } else if (emptyStateInfo) {
    return <EmptyStateContainer><EmptyState {...emptyStateInfo} /></EmptyStateContainer>
  } else {
    return null
  }
}

export default InfoBox
