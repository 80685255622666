import React from 'react'
import { css as glamorCSS } from 'glamor'
import HoverableHeader from '../../hoverableHeader'
import ExpensesBarChart from '../../expensesBarChart'
import { CONTENT_TOOLTIP_TYPES } from '@components/barsChartBox/chart/view'
import { withTheme, css } from 'styled-components'

const HEADER_HEIGHT = '50px'
const BOX_PADDING = '20px'

const Header = css`
  min-height: ${HEADER_HEIGHT};
  padding: 7px ${BOX_PADDING} 0 ${BOX_PADDING};
  border-bottom: 1px solid ${({ theme }) => theme.palette.border.primary};
`

const generateCSS = ({ theme }) => ({
  main: glamorCSS({
    height: '100%',
    border: `1px solid ${theme.palette.border.primary}`,
    borderRadius: '8px'
  }),
  chart: glamorCSS({
    height: `calc(100% - ${HEADER_HEIGHT})`,
    display: 'flex',
    alignItems: 'center'
  })
})

const className = 'expensesBreakdown'

class ExpensesBreakdown extends React.Component {
  constructor (props) {
    super(props)
    const { theme } = props
    this.state = {
      CSS: generateCSS({ theme })
    }
  }

  componentDidUpdate (prevProps) {
    if (prevProps.theme !== this.props.theme) {
      this.setState({
        CSS: generateCSS({ theme: this.props.theme })
      })
    }
  }

  render () {
    return (
      <div className={className} {...this.state.CSS.main}>
        <HoverableHeader overrideStyle={Header} hoverClass={className} outerHoverStyle={'#mostUsedApps:hover &'} header='expenses breakdown' subHeader='Last 12 months' />
        <div {...this.state.CSS.chart}>
          <ExpensesBarChart tooltipType={CONTENT_TOOLTIP_TYPES.CATEGORIES} chartHeight={200} chartOverrideStyle={{ padding: '10px 24px' }} />
        </div>
      </div>
    )
  }
}

export default withTheme(ExpensesBreakdown)
